<template>
  <div class="bodyView"
       :style="$HeaderVisible?'padding-top: 64px;':''">
    <slider-menu :menulist="getmenulist"
                 @onsel="onurlsel"></slider-menu>
    <div class="rightView">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import SliderMenu from '@/components/common/SliderMenu'

export default {
  components: { SliderMenu },
  data () {
    return {
      menulist: [
        {
          pid: 0,
          id: 0,
          child: [
            {
              id: 0,
              name: '首页',
              icon: 'icon-home',
              url: '/'
            }
          ]
        },
        {
          pid: 0,
          id: 1,
          child: [
            {
              id: 1,
              name: '扫码信息',
              icon: 'icon-saoma',
              child: [
                {
                  pid: 1,
                  id: 1,
                  name: '用户信息',
                  url: '/scancode/scanuserinfo',
                  routerName: 'scanuserinfo',
                }
              ]
            }
          ]
        },
      ]
    }
  },
  computed: {
    getmenulist () {
      let menulist = this.menulist
      return menulist
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {

    },
    onurlsel (url) {
      this.$router.replace(url)
    }
  }
}
</script>

<style lang="scss" scoped>
.bodyView {
  height: 100%;
}

.rightView {
  padding-left: 240px;
  height: 100%;
}
</style>
