import {get, post, put} from './http'

/**
 * 登录
 */
export const login = (account, passwd) => post('/tradeapp/user/login/app/ent', {
  phone: account,
  pwd: passwd,
})

/**
 * 注册
 */
export const register = (params) => post('/tradeapp/user/register/app/ent', params)

/**
 * 发送注册短信
 */
export const getRegisterCode = (params) => post('/tradeapp/message/sms/reg', params)

/**
 * 获取登录用户信息
 */
export const getUserInfo = () => get('/tradeapp/user')

/**
 * 修改用户信息
 */
export const editUserInfo = (params) => put('/tradeapp/user', params)

/**
 * 获取修改密码短信
 */
export const getResetPwdCode = (params) => post('/tradeapp/message/sms/forget', params)

/**
 * 忘记密码修改
 */
export const resetPwd = (params) => put('/tradeapp/user/pwd/forget', params)

/**
 * 加入企业
 */
export const joinEnt = (params) => post('/tradeapp/role/ent/beg/join', params)

/**
 * 新增企业
 */
export const createEnt = (params) => post('/tradeapp/ent', params)

/**
 * 通过企业名称获取企业列表
 */
export const searchEnt = (name) => get('/tradeapp/ent/search/list/' + name)

/**
 * 获取发送手机验证码
 * @param params
 */
export const getAboutSmsCode = (params) => post('/tradeapp/message/sms/sales/lead', params)
