<template>
  <div class="sliderMenu">
    <div v-for="(item,index) in menulist"
         :key="index">
      <ul>
        <li :key="indexs"
            :class="{'current':ischildCheck(items)}"
            @click="onChildItemClick(items)"
            v-for="(items,indexs) in item.child">
          <div class="sliderMenu-item p-l-20">
            <Icon :custom="'i-icon '+items.icon"
                  size="18" />
            <span :style="item.id===0?'margin-top:3px':''">{{items.name}}</span>
          </div>
          <ul>
            <li :key="indexss"
                :class="{'current':ischildCheck(itemss)}"
                @click="onChildItemClick(itemss)"
                v-for="(itemss,indexss) in items.child">
              <div class="sliderMenu-item p-l-40">
                <Icon :custom="'i-icon '+itemss.icon"
                      size="18" />
                <span>{{itemss.name}}</span>
              </div>
            </li>
          </ul>
        </li>
      </ul>
      <div class="sliderMenu-line"
           v-if="(index+1)!==menulist.length"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    menulist: Array
  },
  watch: {
    getChange: {
      handler (newValue) {
        let alllist = this.getAllList()
        let list = alllist.filter(res => {
          return res.routerName === newValue.name
        })
        if (list && list.length) {
          let item = list[0]
          this.pid = item.pid
          this.cid = item.id
        }
      },
      deep: true,
      immediate: true
    },
  },
  computed: {
    getChange () {
      let name = this.$route.name
      let menulist = this.menulist
      return {
        name,
        menulist
      }
    }
  },
  data () {
    return {
      pid: 0,
      cid: 0,
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
    },
    getAllList () {
      let list = []
      this.menulist.map(res => {
        list.push(res)
        if (res.child && res.child.length) {
          this.formatlist(list, res.child)
        }
      })
      return list
    },
    formatlist (list, child) {
      child.map(res => {
        list.push(res)
        if (res.child && res.child.length) {
          this.formatlist(list, res.child)
        }
      })
    },
    isparentCheck (item) {
      return this.cid === item.id && item.pid === 0
    },
    ischildCheck (item) {
      return this.cid === item.id && this.pid === item.pid
    },
    onmenuclick (item) {
      if (item.url && this.$route.path !== item.url) {
        this.$emit('onsel', item.url)
      }
    },
    onChildItemClick (item) {
      if (item.url && this.$route.path !== item.url) {
        this.$emit('onsel', item.url)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.sliderMenu {
  width: 240px;
  padding: 10px 2px;
  height: calc(100% - 64px);
  overflow-y: auto;
  background-color: #e6eefc;
  position: fixed;
  left: 0;
  top: 64px;
  li {
    &.current {
      background-color: #0652dd;
      .sliderMenu-item {
        color: #fff;
      }
    }
    .sliderMenu-item {
      height: 48px;
      display: flex;
      align-items: center;
      cursor: pointer;
      ::v-deep .ivu-icon {
        margin-right: 10px;
      }
      span {
        font-size: 16px;
        line-height: 1;
      }
    }
  }
  .sliderMenu-line {
    width: 180px;
    height: 2px;
    background-color: #d2d0e7;
    margin: 10px auto;
  }
}
</style>
