<template>
  <div class='header'
       :style="{backgroundColor:theme==='white'?'#fff':'#35364A',
                color:theme==='white'?'#666':'#fff',
                boxShadow: theme==='white'?'0px 2px 8px 0px rgba(214, 210, 210, 0.5)':''}">
    <div class="header-left"
         @click.stop="$router.replace('/')">
      <Icon custom="i-icon icon-logo"
            size="24"
            class="m-r-15"></Icon>
      <span>农药二维码商品智能追溯系统</span>
    </div>
    <div class="header-right">
      <Badge :count="3"
             type="warning"
             :offset="[1,-3]"
             class="m-r-50"
             style="cursor: pointer">
        <Icon custom="i-icon icon-bell"
              size="22"></Icon>
      </Badge>
      <div class="header-user"
           @click.stop="onClickUserEdit">{{getUserName || '未知用户'}}</div>
      <div class="header-quit"
           @click="loginOut">
        <Icon custom="i-icon icon-off"
              size="18"></Icon>
        <span>退出</span>
      </div>
    </div>
    <UserEdit v-model="userInfoVisible"></UserEdit>
  </div>
</template>

<script>
import UserEdit from './UserEdit'
export default {
  props: {
    theme: {
      type: String,
      default: 'white'
    }
  },
  components: { UserEdit },
  data () {
    return {
      userInfoVisible: false
    }
  },
  computed: {
    getUserAvatar () {
      return this.$store.state.user.avatarImgPath
    },
    getUserName () {
      return this.$store.state.user.userName
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      window.onUserEdit = this.onClickUserEdit
      window.loginOut = this.loginOut
    },
    onClickUserEdit () {
      this.userInfoVisible = true
    },
    loginOut () {
      this.$store.dispatch('handleLogOut')
      this.$router.replace('/login')
    }
  }
}

</script>
<style lang='scss' scoped>
.header {
  width: 100%;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  z-index: 1000;
  .header-left {
    padding-left: 30px;
    display: flex;
    align-items: center;
    cursor: pointer;
    span {
      font-size: 20px;
    }
  }
  .header-right {
    padding-right: 60px;
    display: flex;
    align-items: center;
    .header-user {
      font-size: 16px;
      margin-right: 50px;
      cursor: pointer;
    }
    .header-quit {
      display: flex;
      align-items: center;
      cursor: pointer;
      span {
        font-size: 16px;
        margin-left: 8px;
      }
    }
  }
}
</style>