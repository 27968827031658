<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div class="page-style">
    <HeaderComponent v-if="$HeaderVisible"></HeaderComponent>
    <ContentComponent></ContentComponent>
  </div>
</template>

<script>
import HeaderComponent from '@/components/common/HeaderComponent'
import ContentComponent from '@/components/common/ActivateContentComponent'
export default {
  name: 'yardsindex',
  components: { HeaderComponent, ContentComponent },
  data () {
    return {

    }
  }
}

</script>
<style lang='scss' scoped>
.page-style {
  width: 100%;
  height: 100%;
}
</style>