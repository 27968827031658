<template>
  <div @click.stop="handleClick">
    <input :accept="accept"
           :multiple="multiple"
           @change="handleChange"
           class="input-style"
           ref="input"
           type="file">
    <slot></slot>
  </div>
</template>

<script>
import OssUpload from '../../utils/ossupload'
import config from '../../config/constantconfig'

const uuidv1 = require('uuid/v1')

export default {
  name: 'ImgUpload',
  props: {
    accept: {
      type: String,
      default: 'image/*'
    },
    multiple: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    uploadpath: {
      type: String,
      default: 'test/'
    }
  },
  methods: {
    handleClick () {
      if (this.disabled) return
      this.$refs.input.click()
    },
    handleChange (e) {
      const files = e.target.files
      if (!files) {
        return
      }
      this.uploadFiles(files)
      this.$refs.input.value = null
    },
    uploadFiles (files) {
      let postFiles = Array.prototype.slice.call(files)
      if (!this.multiple) postFiles = postFiles.slice(0, 1)

      if (postFiles.length === 0) return

      let resultarray = []
      let that = this
      this.$emit('uploadstart')
      postFiles.forEach(file => {
        var reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = function () {
          var img = new Image()
          img.src = reader.result
          let width = 0
          let height = 0
          if (img.complete) {
            width = img.width
            height = img.height
            that.upload(file).then(res => {
              res.width = width
              res.height = height
              resultarray.push(res)
              let progressdata = {
                finshnum: resultarray.length,
                totalnum: postFiles.length,
              }
              that.$emit('uploadprogress', progressdata)
              if (resultarray.length === postFiles.length) {
                that.$emit('uploadSuccess', resultarray)
              }
            })
          } else {
            img.onload = function () {
              width = img.width
              height = img.height
              that.upload(file).then(res => {
                res.width = width
                res.height = height
                resultarray.push(res)
                let progressdata = {
                  finshnum: resultarray.length,
                  totalnum: postFiles.length,
                }
                that.$emit('uploadprogress', progressdata)
                if (resultarray.length === postFiles.length) {
                  that.$emit('uploadSuccess', resultarray)
                }
              })
            }
          }
        }
      })
    },
    getUploadPath () {
      let uploadPath = (config.isOnLine ? '' : 'image/') + config.projectName + this.uploadpath
      return uploadPath
    },
    upload (file) {
      let uuid = uuidv1()
      let uploadPath = this.getUploadPath()
      let name = this.getFileSuffix(file.name)
      return OssUpload.put(uploadPath + uuid + name, file).then(r1 => {
        let region = config.imageOssInfo.severurl
        let domain = config.imageOssInfo.url
        return {
          'name': r1.name,
          'url': r1.url.replace(region, domain)
        }
      }, () => {
        return {
          'name': file.name,
          'url': 'error_url'
        }
      })
    },
    getFileSuffix (name) {
      let resultstr = ''
      let index = name.lastIndexOf('.')
      if (index > 0) {
        resultstr = name.substring(index)
      }
      return resultstr
    }
  }
}
</script>

<style lang="scss" scoped>
.input-style {
  display: none;
}
</style>
