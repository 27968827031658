<template>
  <div class="bodyView"
       :style="$HeaderVisible?'padding-top: 64px;':''">
    <slider-menu :menulist="getmenulist"
                 @onsel="onurlsel"></slider-menu>
    <div class="rightView">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import SliderMenu from '@/components/common/SliderMenu'

export default {
  components: { SliderMenu },
  data () {
    return {
      menulist: [
        {
          pid: 0,
          id: 0,
          child: [
            {
              id: 0,
              name: '首页',
              icon: 'icon-home',
              url: '/'
            }
          ]
        },
        {
          pid: 0,
          id: 1,
          child: [
            {
              id: 1,
              name: '追溯码',
              icon: 'icon-qrcode',
              child: [
                {
                  pid: 1,
                  id: 1,
                  name: '商品开码',
                  url: '/yards/openyards',
                  routerName: 'openyards',
                }, {
                  pid: 1,
                  id: 2,
                  name: '开码记录',
                  url: '/yards/yardslist',
                  routerName: 'yardslist',
                }
              ]
            }
          ]
        },
        {
          pid: 0,
          id: 2,
          child: [
            {
              id: 2,
              name: '基础信息',
              icon: 'icon-basicinfo',
              child: [
                {
                  pid: 2,
                  id: 3,
                  name: '商品信息',
                  url: '/yards/goodsinfo',
                  routerName: 'goodsinfo',
                },
                {
                  pid: 2,
                  id: 4,
                  name: '持证人信息',
                  url: '/yards/holderinfo',
                  routerName: 'holderinfo',
                },
                // {
                //   pid: 2,
                //   id: 4,
                //   name: '追溯模板',
                //   url: '/yards/goodsinfo',
                //   routerName: 'goodsinfo',
                // },
              ]
            }
          ]
        },
      ]
    }
  },
  computed: {
    getmenulist () {
      let menulist = this.menulist
      return menulist
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {

    },
    onurlsel (url) {
      this.$router.replace(url)
    }
  }
}
</script>

<style lang="scss" scoped>
.bodyView {
  height: 100%;
}

.rightView {
  padding-left: 240px;
  height: 100%;
}
</style>
