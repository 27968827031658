import {
  del,
  get,
  patch,
  post,
  put
} from './http'

/**
 * 首页统计
 */
export const getIndexStatistics = (id, params) => get('/tradeapp/trace/statistics/count/' + id, params)