// 根据文件后缀名获取文件类型
export const getFileType = (fileName) => {
  // 后缀获取
  var suffix = '';
  // 获取类型结果
  var result = '';
  try {
    var flieArr = fileName.toLowerCase().split('.');
    suffix = flieArr[flieArr.length - 1];
  } catch (err) {
    suffix = '';
  }
  // fileName无后缀返回 false
  if (!suffix) {
    result = false;
    return result;
  }
  // 图片格式
  var imglist = ['png', 'jpg', 'jpeg', 'bmp', 'gif'];
  // 进行图片匹配
  result = imglist.some((item) => {
    return item == suffix;
  });
  if (result) {
    result = 'image';
    return result;
  };
  // 匹配 视频
  var videolist = ['mp4', 'm2v', 'mkv', 'avi', 'rmvb'];
  result = videolist.some((item) => {
    return item == suffix;
  });
  if (result) {
    result = 'video';
    return result;
  };
  // 匹配 音频
  var radiolist = ['mp3', 'wav', 'wmv'];
  result = radiolist.some((item) => {
    return item == suffix;
  });
  if (result) {
    result = 'radio';
    return result;
  }
  // 匹配txt
  var txtlist = ['txt'];
  result = txtlist.some((item) => {
    return item == suffix;
  });
  if (result) {
    result = 'txt';
    return result;
  };
  // 匹配 excel
  var excelist = ['xls', 'xlsx'];
  result = excelist.some((item) => {
    return item == suffix;
  });
  if (result) {
    result = 'excel';
    return result;
  };
  // 匹配 word
  var wordlist = ['doc', 'docx'];
  result = wordlist.some((item) => {
    return item == suffix;
  });
  if (result) {
    result = 'word';
    return result;
  };
  // 匹配 pdf
  var pdflist = ['pdf'];
  result = pdflist.some((item) => {
    return item == suffix;
  });
  if (result) {
    result = 'pdf';
    return result;
  };
  // 匹配 ppt
  var pptlist = ['ppt'];
  result = pptlist.some((item) => {
    return item == suffix;
  });
  if (result) {
    result = 'ppt';
    return result;
  };
  // 匹配压缩包
  var packagelist = ['rar', 'zip', 'arj', 'gz', 'z'];
  result = packagelist.some((item) => {
    return item == suffix;
  });
  if (result) {
    result = 'package';
    return result;
  };
  // 其他 文件类型
  result = 'other';
  return result;
}

// 将日期转化为 今天，昨天等
export const formatDate = (date) => {
  let year = parseInt(date.split("-")[0])
  let mouth = parseInt(date.split("-")[1])
  let day = parseInt(date.split("-")[2])
  let curYear = new Date().getFullYear()
  let curMouth = new Date().getMonth() + 1
  let curDay = new Date().getDate()
  let result = ''
  if (year === curYear) {
    if (mouth === curMouth) {
      let offset = Math.abs(curDay - day);
      if (offset === 0) {
        result = '今天'
      } else if (offset === 1) {
        result = '昨天'
      } else {
        result = mouth + '月' + day + '日'
      }
    } else {
      result = mouth + '月' + day + '日'
    }
  } else {
    result = year + '年' + mouth + '月' + day + '日'
  }
  return result
}

// 获取当前日期，格式YYYY-MM-DD
export const getNowFormatDay = () => {
  var char = "-";
  var nowDate = new Date();
  var day = nowDate.getDate();
  var month = nowDate.getMonth() + 1; //注意月份需要+1
  var year = nowDate.getFullYear();
  //补全0，并拼接
  return year + char + completeDate(month) + char + completeDate(day);

  function completeDate(value) {
    return value < 10 ? "0" + value : value;
  }
}