import {
  del,
  get,
  patch,
  post,
  put
} from './http'

/**
 * 获取仓库列表
 */
export const getStorageList = (params) => get('/tradeapp/trace/storage/list', params)

/**
 * 获取仓库详情
 */
export const getStorageDetail = (id) => get('/tradeapp/trace/storage/' + id)

/**
 * 批量删除仓库
 */
export const deleteStorage = (params) => del('/tradeapp/trace/storage', params)

/**
 * 新建仓库
 */
export const createStorage = (params) => post('/tradeapp/trace/storage', params)

/**
 * 修改仓库
 */
export const editStorage = (id, params) => put('/tradeapp/trace/storage/' + id, params)

/**
 * 获取全部仓库列表
 */
export const getAllStorageList = (params) => get('/tradeapp/trace/storage/ent/list', params)

/**
 * 通过仓库id获取仓管人员列表
 */
export const getStorageAdminList = (id) => get('/tradeapp/trace/storage/manage/list/' + id)