<template>
  <div class='backImage'>
    <div class="contentView"
         v-if="status===0">
      <h2>账号注册</h2>
      <ul>
        <li>
          <span>公司</span>
          <AutoComplete v-model="rEntName"
                        :data="entArr"
                        placeholder="请输入公司名称"
                        transfer
                        @on-search="onSearchEnt"></AutoComplete>
          <!-- <input v-model="rEntName"
                 maxlength="20"
                 placeholder="请输入公司名称" /> -->
        </li>
        <li>
          <span>姓名</span>
          <input v-model="rName"
                 maxlength="20"
                 placeholder="请输入姓名" />
        </li>
        <li>
          <span>手机号</span>
          <input v-model="rPhone"
                 maxlength="11"
                 @keyup="rPhone=formatNumber(rPhone)"
                 placeholder="请输入手机号" />
        </li>
        <li>
          <span>验证码</span>
          <input v-model="smsCode"
                 placeholder="请输入验证码" />
          <Button type="text"
                  :disabled="timeout>0"
                  @click="getRegisterCode"
                  style="width:80px;padding:0">
            {{timeout===0?'获取验证码':`已发送（${timeout}S）`}}
          </Button>
        </li>
        <li>
          <span>密码</span>
          <input v-model="rPwd"
                 type="password"
                 maxlength="16"
                 placeholder="请输入密码（6~16位）" />
        </li>
      </ul>
      <div class="submitBtn m-t-40">
        <Button type="primary"
                @click="register"
                style="width:100px;height:40px;margin-left:120px">立即注册</Button>
        <Button type="text"
                style="width:64px;height:40px;padding:0"
                @click="$router.replace('/login?status=1')">返回登录</Button>
      </div>
    </div>
    <div class="contentView"
         v-if="status===1">
      <h2>欢迎登录</h2>
      <ul>
        <li>
          <span>手机号</span>
          <input v-model="lPhone"
                 maxlength="11"
                 @keyup="lPhone=formatNumber(lPhone)"
                 placeholder="请输入绑定的手机号" />
        </li>
        <li>
          <span>密码</span>
          <input v-model="lPwd"
                 type="password"
                 maxlength="18"
                 placeholder="请输入登录密码" />
        </li>
      </ul>
      <div class="link m-t-30">
        <Checkbox v-model="remPwd">记住密码</Checkbox>
        <router-link to="/login?status=2"
                     replace>忘记密码?</router-link>
      </div>
      <div class="submitBtn m-t-20">
        <Button type="primary"
                @click="login"
                style="width:100px;height:40px">登录</Button>
        <Button style="width:100px;height:40px"
                @click="$router.replace('/login?status=0')">注册</Button>
      </div>
    </div>
    <div class="contentView"
         v-if="status===2">
      <h2>忘记密码</h2>
      <ul>
        <li>
          <span>手机号</span>
          <input v-model="fPhone"
                 maxlength="11"
                 @keyup="fPhone=formatNumber(fPhone)"
                 placeholder="请输入手机号" />
        </li>
        <li>
          <span>验证码</span>
          <input v-model="fSmsCode"
                 placeholder="请输入验证码" />
          <Button type="text"
                  :disabled="fTimeout>0"
                  @click="getResetPwdCode"
                  style="width:80px;padding:0">
            {{fTimeout===0?'获取验证码':`已发送（${fTimeout}S）`}}
          </Button>
        </li>
        <li>
          <span>新密码</span>
          <input v-model="fPwd"
                 type="password"
                 maxlength="16"
                 placeholder="请输入新密码" />
        </li>
      </ul>
      <div class="submitBtn m-t-40">
        <Button type="primary"
                @click="resetPwd"
                style="width:100px;height:40px;margin-left:120px">确认</Button>
        <Button type="text"
                style="width:64px;height:40px;padding:0"
                @click="$router.replace('/login?status=1')">返回登录</Button>
      </div>
    </div>
    <div class="fixedTitle">
      <span>山东老刀网络科技有限公司</span>
      <router-link to="/about">联系我们</router-link>
      <a href="https://beian.miit.gov.cn/" target="_blank">鲁ICP备14027983号-11 </a>
    </div>
  </div>
</template>

<script>
var _ = require('lodash');
import { mapActions } from 'vuex'
import { getRegisterCode, register, getResetPwdCode, resetPwd, searchEnt } from '../../services/userapi'
export default {
  components: {},
  data () {
    return {
      status: 1,
      rEntName: '',
      rName: '',
      rPhone: '',
      rPwd: '',
      smsCode: '',
      timeout: 0,
      isJoinEnt: false,
      lPhone: '',
      lPwd: '',
      remPwd: true,
      fPhone: '',
      fSmsCode: '',
      fTimeout: 0,
      fPwd: '',
      entArr: [],
      entObjArr: [],
      entId: 0
    }
  },
  watch: {
    '$route.query.status': {
      handler (newValue) {
        this.init()
      },
      immediate: true
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    ...mapActions([
      'handleLogin',
      'handlePcLogin'
    ]),
    init () {
      this.handlePcLogin(false)
      if (this.$route.query.status) {
        this.status = parseInt(this.$route.query.status)
      }
    },
    // 只能输入数字
    formatNumber (str) {
      return str.replace(/[^\d]/g, '')
    },
    getRegisterCode () {
      if (this.rPhone === '') return this.$Message.info('请输入手机号')
      let reg = /^1[345678]\d{9}$/
      if (!reg.test(this.rPhone)) return this.$Message.info('请输入正确的手机号')
      let params = {
        phone: this.rPhone
      }
      getRegisterCode(params).then(res => {
        if (res.code === 0) {
          let seconds = 60
          let timer = setInterval(() => {
            --seconds
            this.timeout = seconds
            if (seconds <= 0) {
              clearInterval(timer)
            }
          }, 1000)
        } else {
          if (res.msg) this.$Message.info(res.msg)
        }
      })
    },
    getResetPwdCode () {
      if (this.fPhone === '') return this.$Message.info('请输入手机号')
      let reg = /^1[345678]\d{9}$/
      if (!reg.test(this.fPhone)) return this.$Message.info('请输入正确的手机号')
      let params = {
        phone: this.fPhone
      }
      getResetPwdCode(params).then(res => {
        if (res.code === 0) {
          let seconds = 60
          let timer = setInterval(() => {
            --seconds
            this.fTimeout = seconds
            if (seconds <= 0) {
              clearInterval(timer)
            }
          }, 1000)
        } else {
          if (res.msg) this.$Message.info(res.msg)
        }
      })
    },
    register () {
      if (this.rEntName === '') return this.$Message.info('请输入公司名称')
      if (this.rName === '') return this.$Message.info('请输入姓名')
      if (this.rPhone === '') return this.$Message.info('请输入手机号')
      if (this.smsCode === '') return this.$Message.info('请输入验证码')
      if (this.rPwd === '') return this.$Message.info('请输入密码')
      if (this.rPwd.length < 6) return this.$Message.info('密码不能少于6位')
      let params = {
        entName: this.rEntName,
        name: this.rName,
        phone: this.rPhone,
        pwd: this.rPwd,
        smsCode: this.smsCode
      }
      if (this.entId) {
        params.entId = this.entId
      }
      register(params).then(res => {
        this.$Message.info(res.msg)
        if (res.code === 0) {
          this.$router.replace('/login?status=1')
          this.lPhone = this.rPhone
          this.rName = this.rPhone = this.rPwd = this.smsCode = ''
        }
      })
    },
    login () {
      if (!this.lPhone) return this.$Message.info('请输入手机号')
      if (!this.lPwd) return this.$Message.info('请输入密码')
      this.handleLogin({
        account: this.lPhone,
        passwd: this.lPwd
      }).then(() => {
        this.$router.replace('/')
      }).catch(err => {
        this.$Message.info(err)
      })
    },
    resetPwd () {
      if (this.fPhone === '') return this.$Message.info('请输入手机号')
      if (this.fSmsCode === '') return this.$Message.info('请输入验证码')
      if (this.fPwd === '') return this.$Message.info('请输入新密码')
      if (this.fPwd.length < 6) return this.$Message.info('新密码不能少于6位')
      let params = {
        phone: this.fPhone,
        smsCode: this.fSmsCode,
        newPwd: this.fPwd
      }
      resetPwd(params).then(res => {
        if (res.code === 0) {
          this.$Message.info('重置密码成功')
          this.$router.replace('/login?status=1')
          this.lPhone = this.fPhone
          this.fPhone = this.fSmsCode = this.fPwd = ''
        } else {
          if (res.msg) this.$Message.info(res.msg)
        }
      })
    },
    onSearchEnt: _.throttle(function (query) {
      if (query) {
        searchEnt(query).then(res => {
          if (res.code === 0) {
            let newArr = new Set()
            res.data.forEach(item => {
              newArr.add(item.name)
            })
            this.entArr = [...newArr]
            this.entObjArr = res.data
            this.changeEnt()
          } else {
            this.entArr = []
            this.entObjArr = []
          }
        })
      } else {
        this.entArr = []
        this.entObjArr = []
        this.entId = 0
      }
    }, 1000),
    changeEnt () {
      let entObj = this.entObjArr.find(item => {
        return item.name === this.rEntName
      })
      if (entObj) {
        this.entId = entObj.id
      } else {
        this.entId = 0
      }
    }
  }
}

</script>
<style lang='scss' scoped>
.backImage {
  width: 100%;
  height: 100%;
  background: url("../../assets/common/image_beijing.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  .contentView {
    width: 410px;
    padding: 40px 38px;
    background-color: #fff;
    border-radius: 6px;
    margin-right: 150px;
    h2 {
      font-size: 22px;
      font-weight: 500;
      line-height: 33px;
      text-align: center;
    }
    .link {
      font-size: 14px;
      color: #999;
      display: flex;
      justify-content: space-between;
    }
    ul {
      li {
        height: 48px;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #d8d8d8;
        margin-top: 20px;
        span {
          width: 70px;
          font-size: 14px;
          flex-shrink: 0;
        }
        input {
          font-size: 14px;
          flex: 1;
        }
      }
    }
    .submitBtn {
      display: flex;
      justify-content: space-between;
    }
  }
  .fixedTitle {
    position: fixed;
    bottom: 50px;
    right: 160px;
    color: #fff;
    font-size: 18px;
    a {
      margin-left: 20px;
      text-decoration: underline;
      color: #fff;
    }
  }
}
::v-deep {
  .ivu-input {
    padding: 0;
    color: #333;
    border: none;
    &:focus {
      border: none;
      box-shadow: none;
    }
    &::-webkit-input-placeholder {
      color: #757575;
    }
    &::-moz-input-placeholder {
      color: #757575;
    }
    &::-ms-input-placeholder {
      color: #757575;
    }
  }
}
::v-deep .ivu-select-dropdown-list {
  max-height: 300px;
}
</style>
