import {get, post} from './http'

/**
 * 解析Word内容
 */
export const analysisWord = (params) => post('/tradeapp/tool/office/word/analysis', params)

/**
 * 获取提供的服务类型
 */
export const getServesList = () => get('/tradeapp/servicer/function', {})

/**
 * 获取预算列表
 */
export const getBudgetList = () => get('/tradeapp/servicer/budget', {})

/**
 * 保存销售线索
 * @param params
 */
export const commitClue = (params) => post('/tradeapp/servicer/clue', params)
