<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div class="page-style">
    <HeaderComponent theme="black"
                     v-if="$HeaderVisible"></HeaderComponent>
    <div class="main">
      <div class="box">
        <div class="title">二维码数据</div>
        <div class="statistics">
          <div class="statistics-item">
            <Select v-model="yardsCode"
                    @on-change="onChangeYardsCode"
                    style="width:80px">
              <Option v-for="item in dateArr"
                      :value="item.id"
                      :key="item.id">{{ item.name }}</Option>
            </Select>
            <div class="statistics-num">{{numberFormat(yardsNumber)}}</div>
            <div class="statistics-key">生码数</div>
          </div>
          <div class="statistics-item">
            <Select v-model="activateCode"
                    @on-change="onChangeActivateCode"
                    style="width:80px">
              <Option v-for="item in dateArr"
                      :value="item.id"
                      :key="item.id">{{ item.name }}</Option>
            </Select>
            <div class="statistics-num">{{numberFormat(activateNumber)}}</div>
            <div class="statistics-key">激活数</div>
          </div>
          <div class="statistics-item">
            <Select v-model="scanCode"
                    @on-change="onChangeScanCode"
                    style="width:80px">
              <Option v-for="item in dateArr"
                      :value="item.id"
                      :key="item.id">{{ item.name }}</Option>
            </Select>
            <div class="statistics-num">{{numberFormat(scanNumber)}}</div>
            <div class="statistics-key">扫码数</div>
          </div>
        </div>
        <div class="menu">
          <div class="menu-item"
               @click.stop="onClickMenuYards">
            <div class="menu-icon">
              <Icon custom="i-icon icon-qrcode"
                    size="80"></Icon>
            </div>
            <p>开码管理</p>
          </div>
          <div class="menu-item"
               @click.stop="onClickMenuActivate">
            <div class="menu-icon">
              <Icon custom="i-icon icon-activation"
                    size="80"></Icon>
            </div>
            <p>激活管理</p>
          </div>
          <div class="menu-item"
               @click.stop="onClickMenuScancode">
            <div class="menu-icon">
              <Icon custom="i-icon icon-richscan"
                    size="80"></Icon>
            </div>
            <p>扫码信息</p>
          </div>
          <div class="menu-item">
            <div class="menu-icon">
              <Icon custom="i-icon icon-small_routine"
                    size="80"></Icon>
            </div>
            <p>小程序</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getGoodsList } from '../services/goodsapi';
import { getStorageList } from '../services/storageapi';
import HeaderComponent from '@/components/common/HeaderComponent'
import { getIndexStatistics } from '../services/statisticsapi';
import { getNowFormatDay } from '../utils/utils';
export default {
  name: 'index',
  components: { HeaderComponent },
  data () {
    return {
      yardsCode: 3,
      activateCode: 3,
      scanCode: 3,
      yardsNumber: 0,
      activateNumber: 0,
      scanNumber: 0,
      dateArr: [
        {
          id: 1,
          name: '本月'
        },
        {
          id: 2,
          name: '本季度'
        },
        {
          id: 3,
          name: '本年'
        },
      ]
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      this.getIndexStatistics()
    },
    numberFormat (num) {
      let str = ''
      if (num.length > 5) {
        let decimal = num.substring(num.length - 4, num.length - 4 + 2)
        str = parseFloat(parseInt(num / 10000) + '.' + decimal) + '万';
      } else {
        str = num + ''
      }
      const re = /(?=(?!(\b))(\d{3})+$)/g
      return str.replace(re, ',');
    },
    onChangeYardsCode () {
      this.getIndexStatistics()
    },
    onChangeActivateCode () {
      this.getIndexStatistics()
    },
    onChangeScanCode () {
      this.getIndexStatistics()
    },
    // 获取开始时间
    getStartDate (code) {
      let currentDate = new Date()
      let currentYear = currentDate.getFullYear();
      let currentMonth = currentDate.getMonth() + 1;
      let char = "-";
      let startDate
      switch (code) {
        case 1:
          startDate = currentYear + char + this.completeDate(currentMonth) + char + '01';
          break;
        case 2:
          let spring = 1; //春
          let summer = 4; //夏
          let fall = 7;   //秋
          let winter = 10; //冬
          let startMounth = ''
          //月份从0-11  
          if (currentMonth < 4) {
            startMounth = spring
          } else if (currentMonth < 7) {
            startMounth = summer;
          } else if (currentMonth < 10) {
            startMounth = fall;
          } else {
            startMounth = winter;
          }
          startDate = currentYear + char + this.completeDate(startMounth) + char + '01';
          break;
        case 3:
          startDate = currentYear + char + '01' + char + '01';
          break;
        default:
          break;
      }
      return startDate
    },
    completeDate (value) {
      return value < 10 ? "0" + value : value;
    },
    getIndexStatistics () {
      let endDate = getNowFormatDay()
      let params = {
        startDateActive: this.getStartDate(this.activateCode),
        endDateActive: endDate,
        startDateScan: this.getStartDate(this.scanCode),
        endDateScan: endDate,
        startDateGen: this.getStartDate(this.yardsCode),
        endDateGen: endDate
      }
      getIndexStatistics(1, params).then(res => {
        if (res.code === 0) {
          this.yardsNumber = res.data.genCount
          this.activateNumber = res.data.activeCount
          this.scanNumber = res.data.scanCount
        } else {
          if (res.msg) this.$Message.info(res.msg)
        }
      })
    },
    onClickMenuYards () {
      let params = {
        goodsType: 1,
        pageNum: 1,
        pageSize: 10
      }
      getGoodsList(params).then(res => {
        if (res.data.list.length === 0) {
          this.$router.push('/yards/goodsinfo')
        } else {
          this.$router.push('/yards/openyards')
        }
      })
    },
    onClickMenuActivate () {
      let params = {
        pageNum: 1,
        pageSize: 10
      }
      getStorageList(params).then(res => {
        if (res.data.list.length === 0) {
          this.$router.push('/activate/storageinfo')
        } else {
          this.$router.push('/activate/outstorageactivate')
        }
      })
    },
    onClickMenuScancode () {
      this.$router.push('/scancode')
    }
  }
}

</script>
<style lang='scss' scoped>
.page-style {
  width: 100%;
  height: 100%;
  .main {
    width: 100%;
    height: 100%;
    background-color: #43445c;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    .box {
      width: 884px;
      text-align: center;
      .title {
        font-size: 20px;
      }
      .statistics {
        padding: 80px 130px;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px dashed #fff;
        .statistics-item {
          width: 140px;
          height: 140px;
          border-radius: 50%;
          display: flex;
          flex-direction: column;
          align-items: center;
          padding-top: 10px;
          &:nth-child(1) {
            border: 5px solid #33d9b2;
          }
          &:nth-child(2) {
            border: 5px solid #ffda79;
          }
          &:nth-child(3) {
            border: 5px solid #d3b9eb;
          }
          ::v-deep .ivu-select-selection {
            background-color: transparent;
            border: none;
            color: #fff;
            .ivu-select-arrow {
              color: #fff;
            }
            &:focus {
              border: none;
              box-shadow: none;
            }
          }
          .statistics-num {
            font-size: 18px;
            margin-top: 10px;
          }
          .statistics-key {
            font-size: 14px;
            margin-top: 10px;
          }
        }
      }
      .menu {
        padding-top: 80px;
        display: flex;
        justify-content: space-between;
        .menu-item {
          cursor: pointer;
          &:nth-child(1) .menu-icon {
            background-color: #f5c181;
          }
          &:nth-child(2) .menu-icon {
            background-color: #00bbb8;
          }
          &:nth-child(3) .menu-icon {
            background-color: #38c2ee;
          }
          &:nth-child(4) .menu-icon {
            background-color: #6d82e9;
          }
          .menu-icon {
            width: 120px;
            height: 120px;
            border-radius: 12px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          p {
            font-size: 16px;
            margin-top: 20px;
          }
        }
      }
    }
  }
}
</style>