import {commitClue, getBudgetList, getServesList} from '../../services/utilsapi'

export default {
  state: {
    serviceList: [],
    budgetList: []
  },
  getters: {},
  mutations: {
    setServiceList (state, services) {
      state.serviceList = services || []
    },
    setBudgetList (state, budgetList) {
      state.budgetList = budgetList
    }
  },
  actions: {
    getServesList ({commit, state}) {
      if (state.serviceList && state.serviceList.length) {
        return
      }
      getServesList().then(res => {
        if (res.code === 0) {
          commit('setServiceList', res.data)
        }
      })
    },
    getBudgetList ({commit, state}) {
      if (state.budgetList && state.budgetList.length) {
        return
      }
      getBudgetList().then(res => {
        if (res.code === 0) {
          commit('setBudgetList', res.data)
        }
      })
    },
    commitClue (store, params) {
      return commitClue(params)
    }
  }
}
