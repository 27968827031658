import {editUserInfo, getAboutSmsCode, getUserInfo, login} from '@/services/userapi'
import {getToken, setToken} from '@/utils/tokenutils'

export default {
  state: {
    userId: '',
    phone: '',
    userName: '',
    avatarImgPath: '',
    hasGetInfo: false,
    token: getToken(),
    roleType: 0,
    entName: '',
    entId: '',
    entLogo: ''
  },
  getters: {},
  mutations: {
    setUserId (state, id) {
      state.userId = id
    },
    setPhone (state, phone) {
      state.phone = phone
    },
    setUserName (state, name) {
      state.userName = name
    },
    setAvatar (state, avatarPath) {
      state.avatarImgPath = avatarPath
    },
    setHasGetInfo (state, status) {
      state.hasGetInfo = status
    },
    setToken (state, token) {
      state.token = token
      setToken(token)
    },
    setRoleType (state, roleType) {
      state.roleType = roleType
    },
    setEntName (state, entName) {
      state.entName = entName
    },
    setEntId (state, entId) {
      state.entId = entId
    },
    setEntLogo (state, entLogo) {
      state.entLogo = entLogo
    }
  },
  actions: {
    handlePcLogin () {
      try {
        UserInfoHandle.SetLogin(isLogin)
      } catch (e) {
        console.log(e)
      }
    },
    handleLogin ({
                   commit
                 }, {
                   account,
                   passwd
                 }) {
      return new Promise((resolve, reject) => {
        login(account, passwd).then(res => {
          if (res.code === 0) {
            const data = res.data
            commit('setToken', data.token)
            resolve(data)
          } else {
            reject(res.msg)
          }
        }).catch(err => {
          reject(err)
        })
      })
    },
    getUserInfo ({
                   commit
                 }) {
      return new Promise((resolve, reject) => {
        try {
          getUserInfo().then(res => {
            if (res.code === 0) {
              const data = res.data
              commit('setUserId', data.userId)
              commit('setAvatar', data.icon)
              commit('setPhone', data.phone)
              commit('setUserName', data.name)
              commit('setHasGetInfo', true)
              commit('setRoleType', data.roleType)
              commit('setEntName', data.currentEnt.name)
              commit('setEntId', data.currentEnt.id)
              commit('setEntLogo', data.currentEnt.logoUrl)
              resolve(data)
              try {
                UserInfoHandle.SetLogin(true)
                this.$HeaderVisible = false
              } catch (e) {
                console.log(e)
              }
              try {
                UserInfoHandle.SetUser(data.name, data.icon)
              } catch (e) {
                console.log(e)
              }
            } else {
              reject()
            }
          }, error => {
            reject(error)
          })
        } catch (e) {
          reject(e)
        }
      })
    },
    /**
     * 退出登录
     * @param commit
     */
    handleLogOut ({
                    commit
                  }) {
      commit('setToken', '')
      commit('setHasGetInfo', false)
    },
    editUserInfo (store, params) {
      return editUserInfo(params)
    },
    getAboutSmsCode (store, params) {
      return getAboutSmsCode(params)
    }
  }
}
