<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div class="page-style">
    <HeaderComponent theme="black"></HeaderComponent>
    <div class="main">
      <div class="content1"
           v-if="status===0">
        <img src="../../assets/common/image_weibangding.png"
             class="icon">
        <ul class="form">
          <li class="form-li">
            <span>选择您要关联的企业:</span>
            <AutoComplete v-model="entName"
                          :data="entArr"
                          placeholder="选择您要关联的企业"
                          transfer
                          @on-search="onSearchEnt"
                          style="width:500px"></AutoComplete>
          </li>
        </ul>
        <div class="submitBtn m-t-60">
          <Button type="primary"
                  @click="relevanceEnt"
                  style="width:160px;height:40px;margin-right:100px">绑定</Button>
          <Button style="width:160px;height:40px;"
                  @click="$store.dispatch('handleLogOut')">退出</Button>
        </div>
      </div>
      <div class="content2"
           v-if="status===1||status===2">
        <img src="../../assets/common/image_yibangding.png"
             class="icon">
        <h2 v-if="status===1">您已申请成功，请等待审核</h2>
        <h2 v-if="status===2">您已绑定企业</h2>
        <div class="submitBtn m-t-100">
          <Button type="primary"
                  @click="goToIndex"
                  style="width:160px;height:40px">去首页</Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
var _ = require('lodash');
import { mapActions } from 'vuex'
import { searchEnt, createEnt, joinEnt } from '../../services/userapi'
import HeaderComponent from '@/components/common/HeaderComponent'
export default {
  name: 'index',
  components: { HeaderComponent },
  data () {
    return {
      status: 0,
      entName: '',
      entArr: [],
      entObjArr: [],
      entId: 0
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    ...mapActions([
      'getUserInfo'
    ]),
    init () {
      this.getUserInfo().then(res => {
        if (res.currentEnt.name) {
          this.status = 2
        }
      })
    },
    onSearchEnt: _.throttle(function (query) {
      if (query) {
        searchEnt(query).then(res => {
          if (res.code === 0) {
            let newArr = new Set()
            res.data.forEach(item => {
              newArr.add(item.name)
            })
            this.entArr = [...newArr]
            this.entObjArr = res.data
            this.changeEnt()
          } else {
            this.entArr = []
            this.entObjArr = []
          }
        })
      } else {
        this.entArr = []
        this.entObjArr = []
        this.entId = 0
      }
    }, 1000),
    changeEnt () {
      let entObj = this.entObjArr.find(item => {
        return item.name === this.entName
      })
      if (entObj) {
        this.entId = entObj.id
      } else {
        this.entId = 0
      }
    },
    relevanceEnt () {
      if (this.entId) {
        let params = {
          entId: this.entId
        }
        joinEnt(params).then(res => {
          if (res.code === 0) {
            this.status = 1
          } else {
            if (res.msg) this.$Message.info(res.msg)
          }
        })
      } else {
        let params = {
          entName: this.entName
        }
        createEnt(params).then(res => {
          if (res.code === 0) {
            this.status = 1
          } else {
            if (res.msg) this.$Message.info(res.msg)
          }
        })
      }
    },
    goToIndex () {
      if (this.status === 1) {
        this.getUserInfo().then(res => {
          if (res.currentEnt.name) {
            this.$router.replace('/')
          } else {
            this.$Message.info('请等待审核')
          }
        })
      } else {
        this.$router.replace('/')
      }
    }
  }
}

</script>
<style lang='scss' scoped>
.page-style {
  width: 100%;
  height: 100%;
  .main {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .content1 {
      text-align: center;
      .icon {
        width: 350px;
        height: 355px;
      }
      .form {
        margin-top: 90px;
        .form-li {
          display: flex;
          align-items: center;
          span {
            font-size: 20px;
          }
        }
      }
      .submitBtn {
        display: flex;
        justify-content: center;
      }
    }
    .content2 {
      text-align: center;
      .icon {
        width: 328px;
        height: 227px;
      }
      h2 {
        font-size: 32px;
        margin-top: 50px;
      }
      .submitBtn {
        display: flex;
        justify-content: center;
      }
    }
  }
}
</style>