import config from '../config/constantconfig'

let OSS = require('ali-oss')
let client = new OSS({
  region: config.imageOssInfo.region,
  accessKeyId: config.imageOssInfo.accessKeyId,
  accessKeySecret: config.imageOssInfo.accessKeySecret,
  bucket: config.imageOssInfo.bucket
})

async function put (objName, localfile) {
  try {
    let result = await client.put(objName, localfile)
    return result
  } catch (e) {
  }
}

function asyncput (objName, localfile) {
  return client.put(objName, localfile)
}

// 分片上传
async function multipartUpload (objName, localfile, fun) {
  try {
    let result = await client.multipartUpload(objName, localfile, {
      progress: fun
    })
    let resultinfo = {
      size: result.res.size,
      rt: result.res.rt,
      url: result.name
    }
    return resultinfo
  } catch (e) {
    // 捕获超时异常
    if (e.code === 'ConnectionTimeoutError') {
      // do ConnectionTimeoutError operation
    }
  }
}

export default {
  put,
  asyncput,
  multipartUpload
}
