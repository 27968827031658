import {
  del,
  get,
  patch,
  post,
  put
} from './http'

/**
 * 获取商品列表
 */
export const getGoodsList = (params) => get('/tradeapp/trace/goods/ent/list', params)

/**
 * 获取证件列表
 */
export const getLicenseList = (params) => get('/tradeapp/license/list', params)

/**
 * 批量导入商品
 */
export const importGoods = (params) => post('/tradeapp/trace/goods/license', params)

/**
 * 获取商品详情
 */
export const getGoodsDetail = (id) => get('/tradeapp/trace/goods/ent/' + id)

/**
 * 批量删除商品
 */
export const deleteGoods = (params) => del('/tradeapp/trace/goods/list', params)

/**
 * 获取证件分类列表
 */
export const getLicenseTypeList = (params) => get('/tradeapp/license/class/list', params)

/**
 * 获取证件剂型列表
 */
export const getLicenseDosageList = (params) => get('/tradeapp/license/formulation/list', params)

/**
 * 新增商品
 */
export const createGoods = (params) => post('/tradeapp/trace/goods', params)

/**
 * 修改商品
 */
export const editGoods = (id, params) => patch('/tradeapp/trace/goods/' + id, params)

/**
 * 验证商品规格是否符合标准
 */
export const verifySpec = (type, params) => get('/tradeapp/trace/goods/spec/' + type, params)