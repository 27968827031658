<template>
  <div id="app">
    <router-view class="page-content-style" />
  </div>
</template>

<script>
export default {
  mounted () {
    try {
      CefSharp.BindObjectAsync('UserInfoHandle')
    } catch (err) {
      console.log(err);
    }
  }
}
</script>

<style lang="scss">
#app {
  font-family: PingFangSC-Regular, PingFang SC，Avenir, Helvetica, Arial,
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  height: 100%;
  // background-color: #f0f3fa;
}

body,
html {
  height: 100%;
  overflow: hidden;
}

.page-content-style {
  height: 100%;
  overflow: auto;
}
</style>
