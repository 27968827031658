import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import './scss/index.scss'

// iview
import ViewUI from 'view-design'
import 'view-design/dist/styles/iview.css';
import './scss/my-theme/index.less'
Vue.use(ViewUI);

import { Swipe, SwipeItem } from 'vant';
import 'vant/lib/index.css';
Vue.use(Swipe);
Vue.use(SwipeItem);

Vue.config.productionTip = false

Vue.prototype.$HeaderVisible = true

export default new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')