<template>
  <Modal :value="visible"
         title="用户信息"
         footer-hide
         @on-visible-change="onModalClose"
         width="780">
    <div class="userInfo">
      <div class="userInfo-form">
        <div class="userInfo-left m-r-80">
          <ul>
            <li>
              <div class="userInfo-key">
                <span style="color:#D0021B">*</span>
                <span>姓名:</span>
              </div>
              <Input style="width: 240px;"
                     v-model="name" />
            </li>
            <li>
              <div class="userInfo-key">
                <span style="color:#D0021B">*</span>
                <span>性别:</span>
              </div>
              <RadioGroup v-model="sex"
                          style="line-height:30px">
                <Radio :label="1">男</Radio>
                <Radio :label="2"
                       style="margin-left: 10px">女</Radio>
              </RadioGroup>
            </li>
            <li>
              <div class="userInfo-key">
                <span style="color:#D0021B">*</span>
                <span>电话:</span>
              </div>
              <div class="userInfo-value"
                   style="width: 240px;">{{phone}}</div>
            </li>
            <li>
              <div class="userInfo-key">
                <span style="color:#D0021B">*</span>
                <span>QQ:</span>
              </div>
              <Input style="width: 240px;"
                     v-model="qq" />
            </li>
            <li>
              <div class="userInfo-key">
                <span style="color:#D0021B">*</span>
                <span>微信:</span>
              </div>
              <Input style="width: 240px;"
                     v-model="wechat" />
            </li>
            <li>
              <div class="userInfo-key">
                <span style="color:#D0021B">*</span>
                <span>邮箱:</span>
              </div>
              <Input style="width: 240px;"
                     v-model="email" />
            </li>
          </ul>
        </div>
        <div class="userInfo-right">
          <ul>
            <li>
              <div class="userInfo-key">
                <span style="color:#D0021B">*</span>
                <span>头像:</span>
              </div>
              <ImgUpload @uploadSuccess="uploadImageSuccess"
                         uploadpath="/common/logo/">
                <div class="userInfo-pic">
                  <div class="userInfo-pic-mask">
                    <Icon custom="i-icon icon-uploading"
                          size="46"
                          class="m-b-5"></Icon>
                    点击更换
                  </div>
                  <img :src="logo? logo:require('../../assets/common/ic_youxiang.png')">
                </div>
              </ImgUpload>
            </li>
          </ul>
        </div>
      </div>
      <div class="flex j-center m-t-60">
        <Button @click="onUserEditClick"
                style="width: 100px;margin-right: 30px"
                type="primary">确定</Button>
        <Button @click="onClickCancle"
                style="width: 100px;">取消</Button>
      </div>
    </div>
  </Modal>
</template>

<script>
import { mapActions } from 'vuex'
import ImgUpload from './ImgUpload'

export default {
  components: { ImgUpload },
  model: {
    prop: 'visible',
    event: 'change'
  },
  props: {
    visible: Boolean
  },
  data () {
    return {
      logo: '',
      name: '',
      sex: 0,
      phone: '',
      qq: '',
      wechat: '',
      email: ''
    }
  },
  methods: {
    // 点击取消
    onClickCancle () {
      this.$emit('change', false)
    },
    uploadImageSuccess (data) {
      let res = data[0]
      this.logo = res.url
    },
    onModalClose (status) {
      if (!status) {
        this.resetUserInfo()
        this.$emit('change', false)
      } else {
        this.getUserInfo().then(res => {
          this.logo = res.icon
          this.name = res.name
          this.sex = res.gender || 0
          this.phone = res.phone
          this.qq = res.qq
          this.wechat = res.wechat
          this.email = res.email
        })
      }
    },
    resetUserInfo () {
      this.logo = ''
      this.name = ''
      this.sex = 0
      this.phone = ''
      this.qq = ''
      this.wechat = ''
      this.email = ''
    },
    onUserEditClick () {
      if (!this.logo) return this.$Message.info('请上传头像')
      if (!this.name) return this.$Message.info('请填写姓名')
      if (!this.sex) return this.$Message.info('请选择性别')
      if (!this.qq) return this.$Message.info('请填写qq')
      if (!this.wechat) return this.$Message.info('请填写微信')
      if (!this.email) return this.$Message.info('请填写邮箱')
      let params = {
        icon: this.logo,
        name: this.name,
        gender: this.sex,
        qq: this.qq,
        wechat: this.wechat,
        email: this.email
      }
      this.editUserInfo(params).then(res => {
        if (res.code === 0) {
          this.$Message.info('修改成功')
          this.getUserInfo()
          this.$emit('change', false)
        } else {
          if (res.msg) this.$Message.info(res.msg)
        }
      })
    },
    ...mapActions([
      'getUserInfo',
      'editUserInfo'
    ])
  }
}
</script>

<style lang='scss' scoped>
.userInfo {
  padding: 24px;
  .userInfo-form {
    display: flex;
    justify-content: center;
    li {
      display: flex;
      margin-bottom: 30px;
      .userInfo-key {
        font-size: 14px;
        line-height: 32px;
        width: 60px;
      }
      .userInfo-value {
        font-size: 14px;
        line-height: 32px;
      }
    }
    .userInfo-pic {
      width: 140px;
      height: 140px;
      border-radius: 50%;
      overflow: hidden;
      position: relative;
      cursor: pointer;
      img {
        width: 100%;
        height: 100%;
      }
      .userInfo-pic-mask {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.3);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: #fff;
      }
    }
  }
}
</style>
