import Login from '../views/login/Login.vue'
import RelevanceEnt from '../views/login/RelevanceEnt.vue'
import Index from '../views/Index.vue'
import Yards from '../views/openyards/YardsIndex.vue'
import Activate from '../views/activate/ActivateIndex.vue'
import Scancode from '../views/scancode/ScancodeIndex.vue'

export default [{
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      norequirelogin: true
    }
  },
  {
    path: '/relevanceent',
    name: 'relevanceent',
    component: RelevanceEnt
  },
  {
    path: '/',
    name: 'index',
    component: Index
  },
  {
    path: '/yards',
    name: 'yards',
    redirect: '/yards/openyards',
    component: Yards,
    children: [{
      path: 'openyards',
      name: 'openyards',
      component: () => import('../views/openyards/yards/OpenYards')
    }, {
      path: 'yardslist',
      name: 'yardslist',
      component: () => import('../views/openyards/yards/YardsList')
    }, {
      path: 'goodsinfo',
      name: 'goodsinfo',
      component: () => import('../views/openyards/basicInfo/GoodsInfo')
    }, {
      path: 'holderinfo',
      name: 'holderinfo',
      component: () => import('../views/openyards/basicInfo/HolderInfo')
    }, ]
  },
  {
    path: '/activate',
    name: 'activate',
    redirect: '/activate/instorageactivate',
    component: Activate,
    children: [{
      path: 'instorageactivate',
      name: 'instorageactivate',
      component: () => import('../views/activate/instorage/InStorageActivate')
    }, {
      path: 'instorageactivatelist',
      name: 'instorageactivatelist',
      component: () => import('../views/activate/instorage/InStorageActivateList')
    }, {
      path: 'outstorageactivate',
      name: 'outstorageactivate',
      component: () => import('../views/activate/outstorage/OutStorageActivate')
    }, {
      path: 'outstorageactivatelist',
      name: 'outstorageactivatelist',
      component: () => import('../views/activate/outstorage/OutStorageActivateList')
    }, {
      path: 'storageinfo',
      name: 'storageinfo',
      component: () => import('../views/activate/basicInfo/StorageInfo')
    }, {
      path: 'dealerinfo',
      name: 'dealerinfo',
      component: () => import('../views/activate/basicInfo/DealerInfo')
    }, {
      path: 'storekeeperinfo',
      name: 'storekeeperinfo',
      component: () => import('../views/activate/basicInfo/StorekeeperInfo')
    }, ]
  },
  {
    path: '/scancode',
    name: 'scancode',
    redirect: '/scancode/scanuserinfo',
    component: Scancode,
    children: [{
      path: 'scanuserinfo',
      name: 'scanuserinfo',
      component: () => import('../views/scancode/scancodeinfo/UserInfo')
    }, ]
  },
  {
    path: '/tracecode',
    name: 'tracecode',
    component: () => import('../views/tracecode/TraceInfo'),
    meta: {
      norequirelogin: true
    }
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/aboutus/About'),
    meta: {
      norequirelogin: true
    }
  },
  {
    path: '*',
    redirect: '/'
  }
]